import React from "react";
import Step1 from "steps/Step1";
import Step2 from "steps/Step2";
import Step3 from "steps/Step3";
import { ConfirmationResult, User, getAuth } from "firebase/auth";
import { PAGE_TITLE } from "./constants";
import { trackPageView } from "tracking";
import * as Styled from "./App.styles";

const App = () => {
  const currentUser = getAuth().currentUser;
  const [activeStep, setActiveStep] = React.useState<number>(currentUser ? (currentUser.phoneNumber ? 3 : 2) : 1);
  const [user, setUser] = React.useState<User | null>(currentUser);
  const [phone, setPhone] = React.useState<string>("");
  const [formPhoneError, setFormPhoneError] = React.useState('');
  const [confirmationResult, setConfirmationResult] = React.useState<ConfirmationResult>();

  React.useEffect(() => {
    window.document.title = PAGE_TITLE[`STEP_${activeStep}`];
    trackPageView();
  }, [activeStep]);

  return (
    <Styled.App>
      <Step1
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        setUser={setUser}
        setPhone={setPhone}
        formPhoneError={formPhoneError}
        setFormPhoneError={setFormPhoneError}
        setConfirmationResult={setConfirmationResult}
      />

      <Step2
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        user={user}
        phone={phone}
        setFormPhoneError={setFormPhoneError}
        confirmationResult={confirmationResult}
        setConfirmationResult={setConfirmationResult}
      />

      <Step3 activeStep={activeStep} />

      <Styled.RecaptchaContainer id="recaptcha-container" />
    </Styled.App>
  );
};

export default App;
